import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getProduct } from "../../repo/main";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [product, setProduct] = useState({});

    const onGetProduct = async () => {
        await getProduct({ perPage: 10 }).then((res) => {
            setProduct(res);
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        onGetProduct();
    }, []);

    return (
        <MainContext.Provider value={{ navigation, product }}>
            {children}
        </MainContext.Provider>
    );
}

export const UseMainContext = () => {
    return useContext(MainContext);
}