import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../../../../repo/board";

const ProductDetailContext = createContext();

export const ProductDetailContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const params = useParams();
    const [current, setCurrent] = useState({});

    const onGetProduct = async () => {
        await getProduct({ id: params.id }).then((res) => {
            setCurrent(res?.data?.[0] ?? {});
        });
    }

    useEffect(() => {
        onGetProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ProductDetailContext.Provider value={{ navigation, current, setCurrent }}>
            {children}
        </ProductDetailContext.Provider>
    );
}

export const UseProductDetailContext = () => {
    return useContext(ProductDetailContext);
}