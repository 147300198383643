import { useNavigate } from "react-router-dom";
import { logLogo } from "../../../../assets";
import { InputSearch } from "../../atoms";

const Navbar = (props) => {
    const navigation = useNavigate();

    return (
        <nav className="shadow-md z-20 bg-white sticky top-0 flex justify-center">
            <div className="px-3 py-[0.50rem] w-full md:w-[95%] max-w-[66rem]">
                <div className="flex justify-between gap-5 items-center">
                    <div className="grow flex items-center gap-5">
                        <div>
                            <img src={logLogo} alt="LogoMajelisLogika" className="min-w-[2rem] max-w-[2rem] w-8 min-h-[2rem] max-h-[2rem] h-8 object-fill cursor-pointer" onClick={() => navigation('/')} />
                        </div>
                        <div>
                            <InputSearch placeholder="Cari.." />
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <span className="cursor-pointer" onClick={() => navigation('/cart')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                <path d="M17 17h-11v-14h-2"></path>
                                <path d="M6 5l14 1l-1 7h-13"></path>
                            </svg>
                        </span>
                        <span className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 6h16" /><path d="M7 12h13" /><path d="M10 18h10" /></svg>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;