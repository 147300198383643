import axios from "axios";
import { API_AUTH } from "../../config/api";
import { ErrorResponse, SuccessResponse } from "../../utils/response/Response";

export const loginAuth = async ({ body = {} }) => {
    try {
        const response = await axios.post(`${API_AUTH}/login`, body);
        return SuccessResponse(response);
    } catch (error) {
        return ErrorResponse(error.response);
    }
}

export const registerAuth = async ({ body = {} }) => {
    try {
        const response = await axios.post(`${API_AUTH}/register`, body);
        return SuccessResponse(response);
    } catch (error) {
        return ErrorResponse(error.response);
    }
}