import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigation = useNavigate();

    return (
        <div className="hidden md:block">
            <div className="sticky top-14">
                <div className="border px-3 py-2 rounded-lg flex flex-col leading-3">
                    <span className="font-medium">Menu</span>
                    <small>Management your data</small>
                </div>
                <div className="mt-1">
                    <div className="border py-4 rounded-lg sticky top-14">
                        <ul className="w-44 min-w-[11rem] max-w-[11rem]">
                            <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => navigation('/board')}>
                                <div className="w-[7px] rounded-e-md group-hover:bg-blue-500 py-5" />
                                <div className="flex gap-2 pr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l-2 0l9 -9l9 9l-2 0" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                                    <span className="font-semibold text-[12px]">Dasbor</span>
                                </div>
                            </li>
                            <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => navigation('/board/product')}>
                                <div className="w-[7px] rounded-e-md group-hover:bg-blue-500 py-5" />
                                <div className="flex gap-2 pr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" /><path d="M12 12l8 -4.5" /><path d="M12 12l0 9" /><path d="M12 12l-8 -4.5" /></svg>
                                    <span className="font-semibold text-[12px]">Produk</span>
                                </div>
                            </li>
                            <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => navigation('/board/setting')}>
                                <div className="w-[7px] rounded-e-md group-hover:bg-blue-500 py-5" />
                                <div className="flex gap-2 pr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg>
                                    <span className="font-semibold text-[12px]">Pengaturan</span>
                                </div>
                            </li>
                            {/* <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => { }}>
                                <div className="w-[7px] rounded-e-md group-hover:bg-blue-500 py-5" />
                                <div className="flex gap-2 pr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4h6v6h-6z" /><path d="M14 4h6v6h-6z" /><path d="M4 14h6v6h-6z" /><path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /></svg>
                                    <span className="font-semibold text-[12px]">Kategori</span>
                                </div>
                            </li> */}
                            {/* <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => { }}>
                                <div className="w-[7px] rounded-e-md group-hover:bg-blue-500 py-5" />
                                <div className="flex gap-2 pr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 6l8 0" /><path d="M16 6l4 0" /><path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 12l2 0" /><path d="M10 12l10 0" /><path d="M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 18l11 0" /><path d="M19 18l1 0" /></svg>
                                    <span className="font-semibold text-[12px]">Pengaturan</span>
                                </div>
                            </li> */}
                            {/* <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => { }}>
                                <div className="w-[7px] rounded-e-md group-hover:bg-blue-500 py-5" />
                                <div className="flex gap-2 pr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6.5 7h11" /><path d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z" /><path d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z" /></svg>
                                    <span className="font-semibold text-[12px]">Langganan</span>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;