import { Route, Routes } from 'react-router-dom';
import { ProtectedAuth } from './components/board';
import { LoginContextProvider } from './contexts/auth/login/LoginContext';
import { ProductDetailContextProvider } from './contexts/board/product/detail/ProductDetailContext';
import { ProductCreateContextProvider } from './contexts/board/product/form/ProductCreateContext';
import { ProductContextProvider } from './contexts/board/product/ProductContext';
import { DetailContextProvider } from './contexts/detail/DetailContext';
import { MainContextProvider } from './contexts/main/MainContext';
import { DetailPage, LoginPage, MainPage, OverviewPage, ProductCreatePage, ProductDetailPage, ProductPage } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <MainContextProvider>
          <MainPage />
        </MainContextProvider>
      } />
      <Route path="/detail/:id/:slug" element={
        <DetailContextProvider>
          <DetailPage />
        </DetailContextProvider>
      } />
      {/* Auth */}
      <Route path="/login" element={
        <LoginContextProvider>
          <LoginPage />
        </LoginContextProvider>
      } />
      {/* Board */}
      <Route path="/board" element={
        <ProtectedAuth>
          <OverviewPage />
        </ProtectedAuth>
      } />
      {/* Product */}
      <Route path="/board/product" element={
        <ProtectedAuth>
          <ProductContextProvider>
            <ProductPage />
          </ProductContextProvider>
        </ProtectedAuth>
      } />
      <Route path="/board/product/detail/:id" element={
        <ProtectedAuth>
          <ProductDetailContextProvider>
            <ProductDetailPage />
          </ProductDetailContextProvider>
        </ProtectedAuth>
      } />
      <Route path="/board/product/create" element={
        <ProtectedAuth>
          <ProductCreateContextProvider>
            <ProductCreatePage />
          </ProductCreateContextProvider>
        </ProtectedAuth>
      } />
      {/* Setting */}
      <Route path="/board/setting" element={
        <ProtectedAuth>
          <OverviewPage />
        </ProtectedAuth>
      } />
    </Routes>
  );
}

export default App;
