import { createContext, useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { AlertPopup, LoaderPopup } from "../../../components/board";
import { loginAuth } from "../../../repo/auth";
import { setLocalUser } from "../../../utils/storage/LocalStorage";

const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [element, setElement] = useState(null);
    const [controller, setController] = useState({});
    const [error, setError] = useState({});

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onLogin = async () => {
        setElement(<LoaderPopup />);
        setError({});
        let dataBatch = { ...controller };
        await loginAuth({ body: dataBatch }).then((res) => {
            setElement(null);
            res.status !== 200 && setElement(<AlertPopup title={res.message} onTimeout={() => setElement(null)} />);
            res.status !== 200 && setError(res.data);
            res.status === 200 && setLocalUser(res.data);
            res.status === 200 && setElement(<AlertPopup title={'Berhasil login, Mohon tunggu...'} onTimeout={() => navigation('/board')} />);
        });
    }

    return (
        <LoginContext.Provider value={{ navigation, element, controller, error, onSetController, onLogin }}>
            {children}
        </LoginContext.Provider>
    );
}

export const UseLoginContext = () => {
    return useContext(LoginContext);
}