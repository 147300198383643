export const getLocalToken = () => {
    return JSON.parse(localStorage.getItem('app.user'))?.authFromServer?.data ?? '';
}

export const setLocalUser = (value) => {
    localStorage.setItem('app.user', JSON.stringify(value));
}

export const getLocalUser = () => {
    return JSON.parse(localStorage.getItem('app.user'));
}

export const clearLocal = () => {
    localStorage.clear();
}