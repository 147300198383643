const RoundedFile = (props) => {
    let reader = new FileReader();

    const handleInputChange = (event) => {
        const imageFile = event.target.files[0];
        if (!imageFile) {
            console.log('Please select image.');
            return false;
        }

        const randomString = Math.random().toString(36).substring(2, 8);
        const fileName = Date.now() + '-' + randomString + '.webp';

        if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif|webp)$/)) {
            console.log('Please select valid image JPG,JPEG,PNG');
            return false;
        }
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {

                //------------- Resize img code ----------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = img.width;
                var MAX_HEIGHT = img.height;
                var width = MAX_WIDTH;
                var height = MAX_HEIGHT;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var context = canvas.getContext("2d");
                context.drawImage(img, 0, 0, width, height);
                context.canvas.toBlob((blob) => {
                    const file = new File([blob], fileName, {
                        type: "image/webp",
                        lastModified: Date.now()
                    });
                    // console.log(`${MAX_WIDTH}x${MAX_HEIGHT}`);
                    const dataBatch = {
                        file: file,
                        dimention: `${MAX_WIDTH}x${MAX_HEIGHT}`,
                        preview: URL.createObjectURL(file),
                        width: MAX_WIDTH,
                        height: MAX_HEIGHT,
                    }
                    props.onChange && props.onChange(dataBatch);
                }, "image/webp", 0.99);
            };
            img.onerror = () => {
                console.log('Invalid image content.');
                return false;
            };
            //debugger
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
    };

    return (
        <div className="min-h-[5rem] max-h-[5rem] h-20 min-w-[5rem] max-w-[5rem] w-20 border border-dashed border-blue-400 bg-blue-50 rounded-lg flex justify-center items-center relative overflow-hidden cursor-pointer">
            <div className="absolute flex flex-col text-center leading-4">
                <small className="text-blue-400">Photo</small>
            </div>
            {props.value && (<div className="w-full h-full absolute bg-slate-100">
                <img src={props.value} alt="ImageProfile" className="object-cover w-full h-full" />
            </div>)}
            <input type={"file"} accept="image/*" className="w-full h-full opacity-0" onChange={(event) => handleInputChange(event)} />
        </div>
    );
}
export default RoundedFile;