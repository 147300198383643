import { Button, Content, InputArea, InputNumber, InputText, RoundedFile } from "../../../../components/board";
import { UseProductCreateContext } from "../../../../contexts/board/product/form/ProductCreateContext";
import { CurrencyFormatterV2 } from "../../../../utils/formatter/CurrencyFormatter";

const ProductCreatePage = () => {
    const { controller, element, files, setFiels, onSetFiles, onRemoveFiles, onSetController, onSave, onSaveAndAdd } = UseProductCreateContext();

    return (
        <Content element={element}>
            <div className="grow">
                <div className="flex gap-3">
                    <span className="font-bold text-base">Tambah Produk</span>
                </div>
                <div className="border rounded-lg p-3 mt-2">
                    <div className="flex gap-2 flex-wrap">
                        {files?.map((item, index) => {
                            return (
                                <div key={index} className="relative">
                                    <RoundedFile value={item?.preview ?? ''} onChange={(value) => onSetFiles(index, value)} />
                                    <div className="absolute -top-1 -right-1 rounded-full bg-white p-[1px] cursor-pointer" onClick={() => onRemoveFiles(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-500" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M9 12l6 0" /></svg>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="min-h-[5rem] max-h-[5rem] h-20 min-w-[5rem] max-w-[5rem] w-20 border border-dashed border-blue-400 bg-blue-50 rounded-lg flex justify-center items-center relative overflow-hidden cursor-pointer" onClick={() => setFiels([...files, { file: null }])}>
                            <div className="flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-400" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 12h6" /><path d="M12 9v6" /></svg>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4" />
                    <div className="my-2">
                        <div className="flex flex-col leading-4 mb-2">
                            <span className="font-semibold">Nama Produk</span>
                            <small>Berikan nama sesuai dengan produk anda, dan gunakan kata kata yang gampang dicari orang lain</small>
                        </div>
                        <InputText value={controller.name} onChange={(value) => onSetController('name', value)} placeholder="..." />
                    </div>
                    <div className="my-2 flex flex-wrap gap-4">
                        <div className="grow">
                            <div className="flex flex-col leading-4 mb-2">
                                <span className="font-semibold">Harga</span>
                                <small>Masukan harga dan tidak boleh kosong</small>
                            </div>
                            <InputText value={CurrencyFormatterV2(controller.price)} onChange={(value) => onSetController('price', isNaN(parseFloat(value.replace(/\./g, ''))) ? 0 : parseFloat(value.replace(/\./g, '')))} placeholder="1.000" />
                        </div>
                        <div className="grow">
                            <div className="flex flex-col leading-4 mb-2">
                                <span className="font-semibold">Potongan Harga</span>
                                <small>Contoh: jika harga 10.000 diskon 10% maka masukan harga = 9.000, dan potongan = 10.000</small>
                            </div>
                            <InputText value={CurrencyFormatterV2(controller.piece)} onChange={(value) => onSetController('piece', isNaN(parseFloat(value.replace(/\./g, ''))) ? 0 : parseFloat(value.replace(/\./g, '')))} placeholder="1.000" />
                        </div>
                    </div>
                    <div className="my-2 w-56">
                        <div className="flex flex-col leading-4 mb-2">
                            <span className="font-semibold">Stok</span>
                            <small>Jumlah produk yang tersedia</small>
                        </div>
                        <InputNumber value={controller.qty} onChange={(value) => onSetController('qty', value)} placeholder="..." />
                    </div>
                    <div className="my-2">
                        <div className="flex flex-col leading-4 mb-2">
                            <span className="font-semibold">Detail</span>
                            <small>Masukan detail produk dengan jelas dan mudah dimengerti orang lain</small>
                        </div>
                        <InputArea value={controller.description} onChange={(value) => onSetController('description', value)} placeholder="..." />
                    </div>
                    <hr className="my-4" />
                    <div className="flex gap-2 justify-end py-2">
                        <Button className="border" onClick={() => onSaveAndAdd()}>Simpan {'&'} Tambah Lagi</Button>
                        <Button className="border" onClick={() => onSave()}>Simpan</Button>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default ProductCreatePage;