import axios from "axios";
import { API_PRODUCT } from "../../../config/api";
import { ErrorResponse, SuccessResponse } from "../../../utils/response/Response";

export const getProduct = async ({ perPage = 5 }) => {
    try {
        var filter = '';
        perPage && (filter += `?per_page=${perPage}`);

        const response = await axios.get(`${API_PRODUCT}${filter}`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching items:', error);
    }
}

export const createProduct = async ({ body = {} }) => {
    try {
        const response = await axios.post(`${API_PRODUCT}/create`, body, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return SuccessResponse(response);
    } catch (error) {
        return ErrorResponse(error.response);
    }
}

export const deleteProduct = async ({ body = null }) => {
    try {
        const response = await axios.post(`${API_PRODUCT}/delete`, body);
        return SuccessResponse(response);
    } catch (error) {
        return ErrorResponse(error.response);
    }
}