import { useEffect } from "react";

const AlertPopup = (props) => {

    useEffect(() => {
        setTimeout(() => props.onTimeout && props.onTimeout(), props.time ?? 3000);
    });

    return (
        <div className="fixed bottom-0 top-0 left-0 right-0 w-full flex justify-center items-center">
            <div className="bg-slate-800 rounded-lg px-7 py-3 opacity-50">
                <span className="text-white font-medium">{props.title}</span>
            </div>
        </div>
    );
}

export default AlertPopup;