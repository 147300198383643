import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar";

const Content = (props) => {
    return (
        <div className="text-[13px] flex flex-col min-h-screen overflow-x-hidden">
            {props.navbar !== false && (<Navbar />)}
            <div className="grow relative flex justify-center">
                <div className={`${props.padding !== false && 'px-3'} pb-16 relative w-full max-w-[66rem]`}>
                    <div className="flex gap-5 py-4 sm-zoom-50">
                        <Sidebar />
                        <div className="w-full md:w-[95%]">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            {props.footer !== false && (<Footer />)}
            {props.element && props.element}
        </div>
    );
}

export default Content;