import axios from "axios";
import { API_PRODUCT } from "../../../config/api";

export const getProduct = async ({ perPage = 5, id = null }) => {
    try {
        var filter = '';

        perPage && (filter += `?per_page=${perPage}`);
        id && (filter += `&filter[id]=${id}`);

        const response = await axios.get(`${API_PRODUCT}${filter}`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching items:', error);
    }
}