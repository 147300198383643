import { Content } from "../../components/landing";
import { UseDetailContext } from "../../contexts/detail/DetailContext";
import { CurrencyFormatter } from "../../utils/formatter/CurrencyFormatter";
import { SlugFormatter } from "../../utils/formatter/SlugFormatter";

const DetailPage = () => {
    const { navigation, cover, current, product, onChangeCover } = UseDetailContext();

    return (
        <Content>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-y-0 md:gap-3 mt-4">
                <div>
                    <div className="sticky top-16">
                        <div className="border aspect-square bg-white rounded-lg overflow-hidden">
                            {cover && <img src={cover} alt="ImageService" className="w-full h-full" />}
                        </div>
                        <div className="mt-2 flex gap-2 overflow-x-auto scrollbar-hidden">
                            {current?.product_photo?.map((item, index) => {
                                return (
                                    <div key={index} className="border rounded-lg w-14 aspect-square overflow-hidden cursor-pointer" onClick={() => onChangeCover(item.path_decode)}>
                                        {item?.path_decode && <img src={item?.path_decode} alt="ImageService" className="w-full h-full" />}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="border rounded-lg p-3">
                        <span className="text-lg font-semibold text-slate-700">{current.name}</span>
                        <hr className="my-3" />
                        <div className="flex gap-3">
                            <span className="text-yellow-600 font-semibold text-lg">{CurrencyFormatter(current.price)}</span>
                            <del className="text-slate-400 font-medium text-lg">{CurrencyFormatter(current.piece)}</del>
                        </div>
                        <div className="flex flex-col mt-3">
                            <span className="font-normal">Jumlah Pesanan</span>
                            <div className="flex items-center mt-1">
                                <div className="py-2 px-4 font-medium border bg-slate-100">-</div>
                                <div className="py-2 px-6 font-medium border-y">1</div>
                                <div className="py-2 px-4 font-medium border bg-slate-100">+</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="border rounded-lg p-3">
                            <h2 className="text-base font-semibold text-slate-700">Detail Produk</h2>
                            <hr className="my-2" />
                            <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: current.description }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-16">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mt-5">
                    {product?.data?.map((item, index) => {
                        return (
                            <div key={index} className="shadow-all rounded-lg cursor-pointer" onClick={() => navigation(`/detail/${item.id}/${SlugFormatter(item.name)}`)}>
                                <div className="aspect-square bg-white rounded-t-lg overflow-hidden flex justify-center items-center">
                                    <div>
                                        {item.product_photo?.[0]?.path_decode && <img src={item.product_photo?.[0]?.path_decode} alt="ImageService" className="w-full h-full" />}
                                        {!item.product_photo?.[0]?.path_decode && <div className="w-full h-full flex justify-center items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-400" width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8h.01" /><path d="M6 13l2.644 -2.644a1.21 1.21 0 0 1 1.712 0l3.644 3.644" /><path d="M13 13l1.644 -1.644a1.21 1.21 0 0 1 1.712 0l1.644 1.644" /><path d="M4 8v-2a2 2 0 0 1 2 -2h2" /><path d="M4 16v2a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v2" /><path d="M16 20h2a2 2 0 0 0 2 -2v-2" /></svg>
                                        </div>}
                                    </div>
                                </div>
                                <div className="bg-slate-50 rounded-b-lg p-2">
                                    <span className="font-semibold">{item.name}</span>
                                    <div className="leading-4 flex flex-col mt-2">
                                        <del className="text-slate-400">{CurrencyFormatter(item.piece)}</del>
                                        <span className="font-semibold text-yellow-600 text-base">{CurrencyFormatter(item.price)}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Content>
    );
}

export default DetailPage;