import { Button, Content, EmptyData, InputCheck, Table, TableBody, TableHead, TableTd, TableTh, TableTr } from "../../../components/board";
import { UseProductContext } from "../../../contexts/board/product/ProductContext";
import { CurrencyFormatter } from "../../../utils/formatter/CurrencyFormatter";
import { DateFormatter } from "../../../utils/formatter/DateFormatter";
import TableLoader from "./component/loader/TableLoader";
import ProductMenu from "./component/menu/ProductMenu";

const ProductPage = () => {
    const { navigation, element, product, onProductDelete } = UseProductContext();

    const renderTable = () => {
        return (
            <Table>
                <TableHead>
                    <TableTr>
                        <TableTh className="w-16 text-center">
                            <InputCheck />
                        </TableTh>
                        <TableTh>Produk</TableTh>
                        <TableTh>Harga</TableTh>
                        <TableTh>Status</TableTh>
                        <TableTh></TableTh>
                    </TableTr>
                </TableHead>
                <TableBody>
                    {product?.data?.map((item, index) => {
                        return (
                            <TableTr key={index}>
                                <TableTd className="text-center">
                                    <InputCheck />
                                </TableTd>
                                <TableTd>
                                    <div className="flex items-center gap-3">
                                        <div>
                                            {item.product_photo?.[0]?.path_decode && <img src={item.product_photo?.[0]?.path_decode} alt="ImageService" className="w-16 max-w-[4rem] min-w-[4rem] h-16 rounded-lg border-2 border-slate-300" />}
                                            {!item.product_photo?.[0]?.path_decode && <div className="w-16 max-w-[4rem] min-w-[4rem] h-16 rounded-lg border-2 border-slate-300 flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-400" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8h.01" /><path d="M6 13l2.644 -2.644a1.21 1.21 0 0 1 1.712 0l3.644 3.644" /><path d="M13 13l1.644 -1.644a1.21 1.21 0 0 1 1.712 0l1.644 1.644" /><path d="M4 8v-2a2 2 0 0 1 2 -2h2" /><path d="M4 16v2a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v2" /><path d="M16 20h2a2 2 0 0 0 2 -2v-2" /></svg></div>}
                                        </div>
                                        <div className="leading-4">
                                            <span className="text-md font-bold">{item.name}</span><br />
                                            <small className="whitespace-pre">Dibuat: {DateFormatter(item.created_at)}</small>
                                        </div>
                                    </div>
                                </TableTd>
                                <TableTd>{CurrencyFormatter(item.price)}</TableTd>
                                <TableTd>
                                    <div className="rounded-full px-2 py-1 text-xs bg-green-100 text-green-700 font-bold inline-block whitespace-pre">
                                        {item.status}
                                    </div>
                                </TableTd>
                                <TableTd>
                                    <div className="flex justify-end">
                                        <ProductMenu product={item} onDelete={() => onProductDelete(item.id)} />
                                    </div>
                                </TableTd>
                            </TableTr>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    return (
        <Content element={element}>
            <div className="grow">
                <div className="flex justify-between items-center">
                    <span className="font-bold text-base">Produk</span>
                    <Button className="border" onClick={() => navigation('/board/product/create')}>Tambah Produk</Button>
                </div>
                <div className="border rounded-lg p-2 mt-4">
                    {Object.keys(product).length === 0 ? <TableLoader /> : product.data.length === 0 ? <EmptyData /> : renderTable()}
                </div>
            </div>
        </Content>
    );
}

export default ProductPage;