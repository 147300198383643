export const SuccessResponse = (response) => {
    return {
        status: response.status,
        message: 'Success',
        data: response?.data?.data,
    };
}

export const ErrorResponse = (response) => {
    return {
        status: response.status,
        message: 'Periksa kembali inputan anda',
        data: response?.data?.errors ?? {},
    };
}