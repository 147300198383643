import { Content } from "../../../components/board";

const OverviewPage = () => {

    return (
        <Content>
            <div className="flex justify-center items-center grow">
                Comming soon
            </div>
        </Content>
    );
}

export default OverviewPage;