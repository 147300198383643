import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const Content = (props) => {
    return (
        <div className="text-[13px] flex flex-col min-h-screen">
            {props.navbar !== false && (<Navbar />)}
            <div className="grow relative flex justify-center">
                <div className={`${props.padding !== false && 'px-3'} pb-16 relative w-full md:w-[95%] max-w-[66rem]`}>
                    {props.children}
                </div>
            </div>
            {props.footer !== false && (<Footer />)}
            {props.element && props.element}
        </div>
    );
}

export default Content;