import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { ConfirmDeleteModal, LoaderPopup } from "../../../components/board";
import { deleteProduct, getProduct } from "../../../repo/board";

const ProductContext = createContext();

export const ProductContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [element, setElement] = useState(false);
    const [product, setProduct] = useState({});

    const onGetProduct = async () => {
        setProduct({});
        await getProduct({}).then((res) => {
            setProduct(res);
        });
    }

    const onDeleteProduct = async ({ product_id = null }) => {
        setElement(<LoaderPopup />);
        await deleteProduct({ body: { id: product_id } }).then((res) => {
            res.status === 200 && onGetProduct();
            res.status === 200 && setElement(false);
        });
    }

    const onProductDelete = async (id) => {
        setElement(<ConfirmDeleteModal onClickOutside={() => setElement(false)} onCancel={() => setElement(false)} onSave={() => onDeleteProduct({ product_id: id })} />);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        onGetProduct();
    }, []);

    return (
        <ProductContext.Provider value={{ navigation, element, product, onProductDelete }}>
            {children}
        </ProductContext.Provider>
    );
}

export const UseProductContext = () => {
    return useContext(ProductContext);
}