import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../components/board";

const ProductMenu = (props) => {
    const ref = useRef();
    const navigation = useNavigate();
    const [isShow, setIsShow] = useState(false);

    const onSetIsShow = () => {
        setIsShow(!isShow);
    }

    const renderPopup = () => {
        return (
            <div className={`rounded-lg bg-white border w-44 min-w-44 max-w-44 absolute top-8 right-0 z-20 ${!isShow && 'hidden'}`}>
                <ul>
                    <li className="py-2 px-4 flex gap-2 items-center hover:bg-slate-100 hover:text-slate-900 hover:font-semibold cursor-pointer" onClick={() => navigation(`/board/product/detail/${props.product.id}`)}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                        </span>
                        <span>Lihat</span>
                    </li>
                    <li className="py-2 px-4 flex gap-2 items-center hover:bg-slate-100 hover:text-slate-900 hover:font-semibold cursor-pointer">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
                        </span>
                        <span>Ubah</span>
                    </li>
                    <li className="py-2 px-4 flex gap-2 items-center hover:bg-slate-100 hover:text-slate-900 hover:font-semibold cursor-pointer text-red-800 border-t" onClick={() => props.onDelete && props.onDelete()}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                        </span>
                        <span>Hapus</span>
                    </li>
                </ul>
            </div>
        );
    }

    const handleClickOutside = (event) => {
        if (!ref?.current?.contains(event.target)) {
            setIsShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="relative" ref={ref}>
            <Button className="border flex justify-between w-20 px-[0.6rem]" onClick={() => onSetIsShow()}>
                <span>Atur</span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                </span>
            </Button>
            {renderPopup()}
        </div>
    );
}

export default ProductMenu;