// import { useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logLogo } from "../../../../assets";

const Navbar = (props) => {
    const navigation = useNavigate();

    return (
        <nav className="shadow-md z-20 bg-white sticky top-0 flex justify-center">
            <div className="px-3 py-[0.50rem] w-full md:w-[95%] max-w-[66rem]">
                <div className="flex justify-between gap-5 items-center">
                    <div className="grow flex items-center gap-5">
                        <div>
                            <img src={logLogo} alt="LogoMajelisLogika" className="min-w-[2rem] max-w-[2rem] w-8 min-h-[2rem] max-h-[2rem] h-8 object-fill cursor-pointer" onClick={() => { }} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <span className="cursor-pointer flex gap-2 items-center" onClick={() => navigation('/')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M9 12h12l-3 -3" /><path d="M18 15l3 -3" /></svg>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;