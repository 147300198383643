import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../../repo/main";

const DetailContext = createContext();

export const DetailContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const params = useParams();
    const [cover, setCover] = useState(null);
    const [current, setCurrent] = useState({});
    const [product, setProduct] = useState({});

    const onGetProduct = async () => {
        await getProduct({ id: params.id }).then((res) => {
            setCurrent(res?.data?.[0] ?? {});
            setCover(res?.data?.[0]?.product_photo?.[0]?.path_decode ?? null);
        });
    }

    const onGetProductRecommended = async () => {
        await getProduct({}).then((res) => {
            setProduct(res);
        });
    }

    const onChangeCover = (path_decode) => {
        setCover(path_decode);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        onGetProduct();
        onGetProductRecommended();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DetailContext.Provider value={{ navigation, cover, current, product, onChangeCover }}>
            {children}
        </DetailContext.Provider>
    );
}

export const UseDetailContext = () => {
    return useContext(DetailContext);
}