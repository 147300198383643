import { Button, Content } from "../../components/landing";
import { UseMainContext } from "../../contexts/main/MainContext";
import { CurrencyFormatter } from "../../utils/formatter/CurrencyFormatter";
import { SlugFormatter } from "../../utils/formatter/SlugFormatter";

const MainPage = () => {
    const { navigation, product } = UseMainContext();

    return (
        <Content>
            <div className="my-4">
                <div className="bg-slate-300 w-full aspect-video md:aspect-auto md:h-96 rounded-lg" />
            </div>
            <div className="w-full relative flex justify-center items-center">
                <hr className="my-5 grow" />
                <div className="absolute border rounded-lg py-1 px-3 bg-white">
                    <span className="font-medium text-base">PRODUK TERBARU</span>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mt-5">
                {product?.data?.map((item, index) => {
                    return (
                        <div key={index} className="shadow-all rounded-lg cursor-pointer" onClick={() => navigation(`/detail/${item.id}/${SlugFormatter(item.name)}`)}>
                            <div className="aspect-square bg-white rounded-t-lg overflow-hidden flex justify-center items-center">
                                <div>
                                    {item.product_photo?.[0]?.path_decode && <img src={item.product_photo?.[0]?.path_decode} alt="ImageService" className="w-full h-full" />}
                                    {!item.product_photo?.[0]?.path_decode && <div className="w-full h-full flex justify-center items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-400" width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8h.01" /><path d="M6 13l2.644 -2.644a1.21 1.21 0 0 1 1.712 0l3.644 3.644" /><path d="M13 13l1.644 -1.644a1.21 1.21 0 0 1 1.712 0l1.644 1.644" /><path d="M4 8v-2a2 2 0 0 1 2 -2h2" /><path d="M4 16v2a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v2" /><path d="M16 20h2a2 2 0 0 0 2 -2v-2" /></svg>
                                    </div>}
                                </div>
                            </div>
                            <div className="bg-slate-50 rounded-b-lg p-2">
                                <span className="font-semibold">{item.name}</span>
                                <div className="leading-4 flex flex-col mt-2">
                                    <del className="text-slate-400">{CurrencyFormatter(item.piece)}</del>
                                    <span className="font-semibold text-yellow-600 text-base">{CurrencyFormatter(item.price)}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="my-5 flex justify-center">
                <Button className="border px-10 py-[0.5rem]">Lihat Semua Buku</Button>
            </div>
        </Content>
    );
}

export default MainPage;