import { Button, Card, Content, InputEmail, InputPassword } from "../../../components/landing";
import { UseLoginContext } from "../../../contexts/auth/login/LoginContext";

const LoginPage = () => {
    const { navigation, element, controller, error, onSetController, onLogin } = UseLoginContext();

    return (
        <Content element={element} navbar={false} footer={false}>
            <div className="flex flex-wrap-reverse justify-center gap-5 py-10 md:py-20">
                <Card className="grow py-10">
                    <div className="leading-4 text-center">
                        <div className="flex justify-center p-5">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-200" width="80" height="80" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 21l18 0" /><path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" /><path d="M5 21l0 -10.15" /><path d="M19 21l0 -10.15" /><path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" /></svg>
                        </div>
                        <span className="font-bold text-lg">Majelis Logika</span><br />
                        <span className="text-xs">Heppy selling for your product</span>
                        <div className="mt-5 flex justify-center">
                            <Button className="border py-[0.5rem]" onClick={() => navigation('/')}>Kembali</Button>
                        </div>
                    </div>
                </Card>
                <div className="px-2 md:px-10 flex justify-center items-center">
                    <div>
                        <span className="font-bold text-lg">Let's Get Started</span><br />
                        <div className="leading-3">
                            <span className="text-xs">Atur dan kelola toko dengan masuk ke halaman dasboard</span>
                        </div>
                        <div className="my-3">
                            <div className="mb-1 text-xs">
                                <span>E-Mail</span>
                            </div>
                            <InputEmail error={error?.email} value={controller.email} onChange={(value) => onSetController('email', value)} placeholder="Masukan email" />
                        </div>
                        <div className="my-3">
                            <div className="mb-1 text-xs">
                                <span>Password</span>
                            </div>
                            <InputPassword error={error?.password} value={controller.password} onChange={(value) => onSetController('password', value)} placeholder="Masukan password" />
                        </div>
                        <div className="my-1">
                            <div>
                                <span className="text-xs">Forgot Password?</span>
                            </div>
                        </div>
                        <div className="my-3">
                            <div>
                                <Button className="border py-[0.55rem] bg-blue-500 text-white" onClick={() => onLogin()}>Masuk</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default LoginPage;