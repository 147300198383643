export const CurrencyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('id-ID', {
        decimalSeparator: '.',
        minimumFractionDigits: 0
    });
    // Format the value using the formatter
    return `Rp${formatter.format(value ?? 0)}`;
}

export const CurrencyFormatterV2 = (value) => {
    const formatter = new Intl.NumberFormat('id-ID', {
        decimalSeparator: '.',
        minimumFractionDigits: 0
    });
    // Format the value using the formatter
    return formatter.format(value ?? 0);
}