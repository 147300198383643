import { Content } from "../../../../components/board";
import { UseProductDetailContext } from "../../../../contexts/board/product/detail/ProductDetailContext";
import { CurrencyFormatter } from "../../../../utils/formatter/CurrencyFormatter";

const ProductDetailPage = () => {
    const { current } = UseProductDetailContext();

    return (
        <Content>
            <div className="border rounded-lg p-3">
                <div className="flex gap-3">
                    <div className="border rounded-lg aspect-square w-20 overflow-hidden">
                        {current.product_photo?.[0]?.path_decode && <img src={current.product_photo?.[0]?.path_decode} alt="ImageService" className="w-full h-full" />}
                    </div>
                    <div className="flex flex-col gap-1">
                        <span className="text-lg font-semibold text-slate-700">{current.name}</span>
                        <div className="flex gap-2">
                            <div className="rounded-full px-3 text-xs bg-green-100 text-green-700 font-bold inline-block whitespace-pre">
                                <small>{current.status}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3 border rounded-lg p-3">
                <div className="mb-2 border-b">
                    <span className="font-semibold">Photo Produk</span>
                </div>
                <div className="mt-2 flex gap-2 overflow-x-auto scrollbar-hidden">
                    {current?.product_photo?.map((item, index) => {
                        return (
                            <div key={index} className="border rounded-lg w-14 aspect-square overflow-hidden cursor-pointer">
                                {item?.path_decode && <img src={item?.path_decode} alt="ImageService" className="w-full h-full" />}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="mt-3 border rounded-lg p-3">
                <div className="mb-2 border-b">
                    <span className="font-semibold">Detail</span>
                </div>
                <div className="mb-2">
                    <table>
                        <tbody>
                            <tr>
                                <td><div className="pr-3">Stok</div></td>
                                <td>: <span className="font-semibold">{current.qty}</span> Produk</td>
                            </tr>
                            <tr>
                                <td><div className="pr-3">Harga</div></td>
                                <td>: {CurrencyFormatter(current.price)}</td>
                            </tr>
                            <tr>
                                <td><div className="pr-3">Potongan</div></td>
                                <td>: {CurrencyFormatter(current.piece)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mb-2 mt-5 border-b">
                    <span className="font-semibold">Deskripsi</span>
                </div>
                <div className="mt-2 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: current.description }} />
            </div>
        </Content>
    );
}

export default ProductDetailPage;