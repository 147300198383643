import { InputCheck, Table, TableBody, TableHead, TableTd, TableTh, TableTr } from "../../../../../components/board";
import RoundedSkeleton from "../../../../../components/board/molecules/skeleton/RoundedSkeleton";


const TableLoader = () => {
    return (
        <Table>
            <TableHead>
                <TableTr>
                    <TableTh className="w-16 text-center">
                        <InputCheck />
                    </TableTh>
                    <TableTh>Produk</TableTh>
                    <TableTh>Harga</TableTh>
                    <TableTh>Status</TableTh>
                    <TableTh></TableTh>
                </TableTr>
            </TableHead>
            <TableBody>
                <TableTr>
                    <TableTd className="text-center">
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                </TableTr>
                <TableTr>
                    <TableTd className="text-center">
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                </TableTr>
                <TableTr>
                    <TableTd className="text-center">
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                </TableTr>
                <TableTr>
                    <TableTd className="text-center">
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                </TableTr>
                <TableTr>
                    <TableTd className="text-center">
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                </TableTr>
                <TableTr>
                    <TableTd className="text-center">
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                    <TableTd>
                        <RoundedSkeleton className="py-2" />
                    </TableTd>
                </TableTr>
            </TableBody>
        </Table>
    );
}

export default TableLoader;