import { Navigate } from "react-router-dom";
import { getLocalUser } from "../../../../utils/storage/LocalStorage";

const ProtectedAuth = ({ children }) => {
    const localData = getLocalUser();
    if (!localData) {
        return <Navigate to={'/'} />
    }

    return children;
}

export default ProtectedAuth;