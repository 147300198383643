const Footer = () => {
    return (
        <div>
            <div className="mb-7 flex justify-center px-3">
                <div className="border rounded-lg flex justify-evenly flex-wrap md:flex-nowrap gap-6 md:gap-0 p-5 w-full md:w-[95%] max-w-[66rem]">
                    <div className="flex flex-col text-center">
                        <div className="mb-5 flex justify-center">
                            <div className="w-14 h-14 rounded-full bg-slate-200"></div>
                        </div>
                        <span className="font-medium text-lg">Simple Order</span>
                        <div className="max-w-52 mt-3">
                            <span className="font-normal">Order cepat tanpa ribet langsung melalui form WhatsApp.</span>
                        </div>
                    </div>
                    <div className="flex flex-col text-center">
                        <div className="mb-5 flex justify-center">
                            <div className="w-14 h-14 rounded-full bg-slate-200"></div>
                        </div>
                        <span className="font-medium text-lg">Fast Response</span>
                        <div className="max-w-52 mt-3">
                            <span className="font-normal">Kami siap melayani dan merespons order Anda dengan cepat.</span>
                        </div>
                    </div>
                    <div className="flex flex-col text-center">
                        <div className="mb-5 flex justify-center">
                            <div className="w-14 h-14 rounded-full bg-slate-200"></div>
                        </div>
                        <span className="font-medium text-lg">Quality Books</span>
                        <div className="max-w-52 mt-3">
                            <span className="font-normal">Kami hanya menjual buku yang benar-benar bermutu dan berkualitas.</span>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="border-t flex justify-center">
                <div className="w-full md:w-[95%] max-w-[66rem] px-3 py-2">
                    <span className="text-slate-400">Copyright &copy; Majelis Logika</span>
                    {/* <span className="mx-2">||</span>
                <span>Support By PT Relasi Symbio Solusi</span> */}
                </div>
            </footer>
        </div>
    );
}

export default Footer;