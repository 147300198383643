import { createContext, useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { AlertPopup, LoaderPopup } from "../../../../components/board";
import { createProduct } from "../../../../repo/board";

const ProductCreateContext = createContext();

export const ProductCreateContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [element, setElement] = useState(null);
    const [controller, setController] = useState({});
    const [files, setFiels] = useState([]);

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onSetFiles = (index, value) => {
        var filesData = [...files];
        filesData[index] = value;
        setFiels(filesData);
    }

    const onRemoveFiles = (index) => {
        var filesData = [...files];
        filesData.splice(index, 1);
        setFiels(filesData);
    }

    const onSave = async () => {
        setElement(<LoaderPopup />);
        var dataBatch = { ...controller };
        files.forEach((item) => {
            dataBatch.file = [...(dataBatch?.file ?? []), item.file];
        });

        await createProduct({ body: dataBatch }).then((res) => {
            setElement(null);
            res.status !== 200 && setElement(<AlertPopup title={res.message} onTimeout={() => setElement(null)} />);
            res.status === 200 && navigation('/board/product');
        });
    }

    const onSaveAndAdd = async () => {
        setElement(<LoaderPopup />);
        var dataBatch = { ...controller };
        files.forEach((item) => {
            dataBatch.file = [...(dataBatch?.file ?? []), item.file];
        });

        await createProduct({ body: dataBatch }).then((res) => {
            setElement(null);
            res.status !== 200 && setElement(<AlertPopup title={res.message} onTimeout={() => setElement(null)} />);
            res.status === 200 && setController({});
            res.status === 200 && setFiels([]);
        });
    }

    return (
        <ProductCreateContext.Provider value={{ navigation, element, controller, files, setFiels, onSetFiles, onRemoveFiles, onSetController, onSave, onSaveAndAdd }}>
            {children}
        </ProductCreateContext.Provider>
    );
}

export const UseProductCreateContext = () => {
    return useContext(ProductCreateContext);
}